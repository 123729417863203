<template>
  <div id="account-change">
    <section class="section head">
      <div class="container">
        <h1>Account Change</h1>
      </div>
    </section>

    <section class="section body">
      <div v-if="!emailSent">
        <h5 v-if="isLegacyUser">
          <strong>Welcome back!</strong>
          Please check your email for a confirmation number and enter it below
          to change your password and reactivate your account.
        </h5>
        <h5 v-else>
          Please check your email for a confirmation number and enter it below
          to change your password.
        </h5>

        <form novalidate="true" @submit.prevent="recovery" v-if="!emailSent">
          <md-field>
            <label for="email">Email Address:</label>
            <md-input type="email" v-model="email" />
          </md-field>

          <md-field :md-toggle-password="false">
            <label for="password">New Password:</label>
            <md-input type="password" v-model="password" />
            <div class="pwd-warn">
              Password must be at least 8 characters, and include UPPER/lower
              case, a number, and a special character (i.e. *&!#@)
            </div>
          </md-field>

          <md-field :md-toggle-password="false">
            <label for="confirmPassword">Confirm Password:</label>
            <md-input type="password" v-model="confirmPassword" />
          </md-field>

          <md-field>
            <label for="confirm">Confirmation Code:</label>
            <md-input type="confirm" v-model="confirm" />
          </md-field>

          <md-button type="submit" class="md-raised md-primary large-width">
            Submit
          </md-button>
        </form>
      </div>

      <div class="sent" v-else>
        <p>Your changes have been made</p>

        <md-button
          v-if="!isLoggedIn"
          class="md-raised md-primary large-width"
          @click="login"
        >
          Click here to login
        </md-button>
      </div>
    </section>
  </div>
</template>
<script>
import { validEmail } from "@/services/shared.js";

export default {
  name: "AccountRecovery",
  metaInfo: function() {
    return {
      title: " - Account Confirmation",
      meta: [
        {
          name: "description",
          content: "Check your email for a confirmation link."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      email: null,
      password: null,
      confirm: null,
      confirmPassword: null,
      emailSent: false
    };
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.state.auth.token != "";
    },
    isLegacyUser: function() {
      return this.$store.state.auth.resetUser != "";
    }
  },
  methods: {
    login: function() {
      this.$emit("showLogin");
    },
    recovery: function() {
      if (!validEmail(this.email)) {
        this.$toastr("error", "Invalid email");
      } else if (this.password !== this.confirmPassword) {
        this.$toastr("error", "Passwords Do Not Match");
      } else {
        var params = {
          email: this.email,
          confirm: this.confirm,
          password: this.password
        };
        this.$store
          .dispatch("auth/accountChange", params)
          .then(() => {
            this.$toastr("success", {
              msg: "Your account has been updated!",
              timeout: 5000
            });
            this.emailSent = true;
          })
          .catch(error => {
            this.$toastr("error", {
              msg: error.response.data["error"],
              timeout: 5000
            });
          });
      }
    }
  },
  mounted: function() {
    if (this.isLegacyUser) {
      this.email = this.$store.state.auth.resetUser;
    }
  }
};
</script>
<style lang="scss">
#account-change {
  section.body {
    padding-top: 40px;
    padding-bottom: 40px;

    h5 {
      text-align: center;
      margin-bottom: 40px;

      strong {
        font-size: 1.5em;
        display: block;
        margin-bottom: 10px;
      }
    }
    form {
      width: 400px;
      display: block;
      margin: 0 auto;
      text-align: center;
    }

    form {
      .md-field {
        flex-direction: column;
        font-size: 1.2em;

        .md-input {
          width: 100%;
        }

        &.md-has-password .md-button {
          top: 30px;
        }
        .pwd-warn {
          font-size: 13px;
          text-align: left;
          white-space: nowrap;
        }
      }
    }

    .sent {
      text-align: center;

      p {
        font-size: 1.4em;
        font-weight: bold;
        width: auto;
      }
    }
  }
}
</style>
